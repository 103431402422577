body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  padding: 7px;
  background-color: #e2e2e2;
  border-radius: 4px;
}

input[type="submit"] {
  background: #515ad8;
  border-radius: 5px;
  color: inherit;
  border: none;
  padding: 3px 7px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: white;
}

input[type="text"] {
  background: inherit;
  border-radius: 5px;
  color: inherit;
  border: 1px solid #afafaf;
  padding: 2px 7px;
  font: inherit;
  outline: inherit;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
